Cake.create('ads', '#ads', {
    root:'BODY',

    handlers:{
        async destroy(e){
            return await this.reset();
        },
        submit(e){

        },
        isConnected(e){
            console.log('ads is connected');
            let _this = this;
            let el = document.getElementById('exampleModal');
            this.data.modal = new bootstrap.Modal(el,{});
            this.data.modal.show();
            el.addEventListener('hide.bs.modal',(e)=>{
                let a = this.data.issubmit || false;
                _this.fire.destroy().then(()=>{
                    // res(a);
                });
            });
        },

        click(){
            window.location = '/redirect/deposit';
        }

    },
    subscribe:{

        lobby:{
            showDepositNow(){
                console.log('here');
                setTimeout(()=>{
                    this.render();
                },2000);
            }
        }
    },
});