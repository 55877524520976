Cake.create('jackpot','#jackpot',{
    handlers:{
        updateJackpot(e){
            return new Promise((res, rej)=>{
                // console.log(e);

                if (e.data != this.data.cache){
                    let current = this.container.jackpot.textContent;

                    // console.log(current);
                    current = current == '...'?0:Number(current.split(',').join(""));
        
                    // e.data = Number(e.data) + (10 * this.data.current);
    
                    // this.data.current += 1
        
                    // console.log(current, e.data);
        
                    let counter = new CountUp(this.container.jackpot, e.data, {startVal:current, decimalPlaces: 2,});
    
                    if (!counter.error) {
                          counter.start(function(){
                        res();
                    });
                    
                    } else {
                      console.error(counter.error);
                        res();
                    }
                } else {
                    setTimeout(()=>{
                        res();
                    },5000);
                };
            })
        }
    },
    subscribe:{
        socket:{
            updateJackpot(e){
                return this.fire.updateJackpot(e);
            },
        }
    },
});