Cake.create('details', '#details', {
    handlers:{
        destroy(){
            return this.reset().then(()=>{
                return this.$scope.set('details', null);
            });
        },
        isConnected(d){

            let {root, emit:{detail}} = d;
            let el = this.html.getElement();

            detail = [
                {name:'rtp',value:'10%'},
                {name:'reels',value:'10'},
                {name:'paylines', value:'10%'},
                {name:'type', value:'ebingo'},
            ];

            this.$scope.set('details', detail).then(()=>{
                if (el){
                    el.classList.add('show');
                    setTimeout(()=>{
                        el.classList.add('showed');
                    },500);
                };
            });



            
            

        },
    },
    subscribe:{
        lobby:{
            renderDetails({container, name}){
                try{
                    this.fire.destroy();
                } catch(err){};
                console.log(container, name);
                this.await.destroy.then(()=>{
                    this.fire('getDetails', name).then(detail=>{
                        console.log(detail);
                        this.render({
                            root:container,emit:{detail},
                        });
                    });
                });
            },
        }    
    },
});