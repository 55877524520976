Cake.create('otp','#otp',{
    root:'.target-form-container',
    handlers:{
        async destroy(){
            return await this.reset();
        },
        isConnected(){
            // console.log('otp is connected');

            this.fire('disableButton');
            setTimeout(()=>{
                this.fire('enableButton');
                
            },3000)
        },
        submit(e){
            let json = this.utils.toFormData(e.target,{json:true});
            return json;
        }
    },
    subscribe:{
        register:{
            openOtp(e){
                
                this.fire('openModal',{btn_type:'Verify OTP'}).then(()=>{
                    this.render();
                });
            }
        },
        header_notlogin:{
            openOtp(e){
                // console.log(e);
                this.fire('openModal',{btn_type:'Verify OTP'}).then(()=>{
                    this.render();
                });
            }
        },
        
        modal_form:{
            destroy(e){
                this.fire.destroy();
            }
        }
    },
});