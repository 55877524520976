Cake.create('modal', '#modal', {
    root:'BODY',

    handlers:{
        async destroy(e){
            return await this.reset();
        },
        submit(e){
            this.data.issubmit = true;
 
            this.data.modal.hide();
        },
        isConnected(e){
            let el = document.getElementById('exampleModal');
            this.data.modal = new bootstrap.Modal(el,{});
            this.data.modal.show();

            let _this = this;


            this.data.dismissed = new Promise((res)=>{



                el.addEventListener('hide.bs.modal',(e)=>{
                    let a = this.data.issubmit || false;
                    _this.fire.destroy().then(()=>{
                        res(a);
                    });
                });
            });
        },
        renderModal(e){
            return new Promise((res=>{
                this.render({
                    data:{
                        title:e.title,
                        message:e.message,
                    }
                }).then(()=>{

    
                    this.data.dismissed.then((d)=>{

                        res(d);
                    });
                });



            }));
        }
    },
    subscribe:{
        openModal:{
            components:['lobby', 'footer'],
            handler(e){
                this.render({hashed:true, emit:{trigger:e}});
            }
        },
        alertUnderMaintenance:{
            components:['lobby'],
            handler(e){

                return this.fire.renderModal({
                    title:e.title,
                    message:e.message,
                });
            }
        },
        contact:{
            destroy(){
                this.fire.destroy(null, false);
            }
        },
    },
});