Cake.create('contact', '#contact', {
    root:'.modal-content',
    handlers:{
        destroy(){
            this.reset();
        },
        submit(e){
            e.preventDefault();

            this.fire('renderSpinner');


            let form = e.target;
            let data = new FormData(form);
            let o = {};
            for (let [key, value] of data.entries()){
                o[key] = sanitize(value);
            };

            function sanitize(string) {
                const map = {
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '"': '&quot;',
                    "'": '&#x27;',
                    "/": '&#x2F;',
                };
                const reg = /[&<>"'/]/ig;
                return string.replace(reg, (match)=>(map[match] || ""));
            };

            
            this.fire('contactUs', o).then((r)=>{
                if (r.request){
                    this.fire('alertContactSubmitSuccess');
                } else {
                    throw new Error(r.error || 'Something went wrong!');
                };
            }).finally(()=>{
                this.fire('destroySpinner');
                this.fire.destroy();
            }).catch(err=>{
                this.fire('alertContactSubmitError', err.message);
            });
        },
    },
    subscribe:{
        modal:{
            renderContact(e){
                this.render({emit:{container:e}});
            },
            destroy(){
                this.fire.destroy();
            },
        }
    },
});