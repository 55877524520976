Cake.create('header', '#header', {
    toggle:{
        burger:{
            sel:'[name=burger]', cls:'is-active',
        },
        navbar:{
            sel:'[name=navbar]', cls:'is-active',
        }
    },
    utils(self){
        this.toProfile = function(){
            let a = document.createElement("a");
            if(Cookies.get('session-ebingo')){
                a.href =`${conf.PROFILE_URL}/#!/`;
            } else if(Cookies.get('x-pt_token')){
                a.href =`${conf.PROFILE_URL}/#!/auth?auth=${Cookies.get('x-pt_token')}`;
            } else {
                return swal.fire({
                    title:'Login',
                  text:'you need to login to your account',
                  icon:'info'
                })
            };
            a.target = '_self';


            // console.log(a.href);
            a.click();
        }
    },
    handlers:{
        isConnected(){
            console.log('header is connected')
            this.fire('verify').then(res=>{


                // this.$scope.set('is_login_test',0);

                if(res && res.status){
                    this.fire('renderIsLogin',{username:res.data.username});
                    this.$scope.set('is_auth',true);
                    
                } else {
                    this.fire('renderNotLogin');
                    this.$scope.set('is_auth',false);
                    
                };
            });
        },

        close(e){
            setTimeout(()=>{
                let w = window.open(location, '_self');
                w.close();
            }, 1000);
        }, 
        burger(e){
            this.toggler('burger');
            this.toggler('navbar');
        },
        updateUser(e){
            // this.fire.updateWallet(e);
            // console.log(e);
            try{
                this.container.player.textContent = e.fullname;
                this.$scope.playername = e.fullname;

            } catch(e){

            }
        },

        deposit(e){
            this.utils.toProfile();
        },
        profile(e){
            this.utils.toProfile();

        },
        withdraw(e){
            this.utils.toProfile();
        }
    },
    subscribe:{

    },
});