Cake.create('login','#login',{
    root:'.target-form-container',
    handlers:{
        async destroy(){
            return await this.reset();
        },
        isConnected(){

        },
        submit(e){
            let json = this.utils.toFormData(e.target,{json:true});
            return json;
        },
        showPassword(e){
            let target = e.target;
            let span = target.closest('SPAN');
            let input = span.previousElementSibling;
            let type = input.getAttribute('type');
            if(type == 'text'){

                input.setAttribute('type','password');
            } else {
                input.setAttribute('type','text');

            }
        }
    },
    subscribe:{
        openLogin:{
            components:['header_notlogin','app'],
            handler(e){
                this.fire('openModal',{btn_type:'Login'}).then(()=>{
                    this.render();
                });
            }
        },
        // header_notlogin:{
        //     openLogin(e){
        //         this.fire('openModal',{btn_type:'Login'}).then(()=>{
        //             this.render();
        //         });
        //     }
        // },
        modal_form:{
            destroy(e){
                this.fire.destroy();
            }
        }
    },
});