require('dotenv').config('../../');





module.exports = {

    BR_URL:'https://uat.bingorepublic.com.ph',
    BASE_URL:"https://uat.888bingo.ph",
    SOCKET_URL:"https://uat.888bingo.ph",
    GAME_DISABLE:[]
};