Cake.create('fb_share','#fb-share',{
    handlers:{
        async destroy(){
            await this.reset();
        }
    },
    subscribe:{
        lobby:{
            async renderFbShare({root,href}){
                console.log(root, href);
                await this.fire.destroy();

                await [...document.querySelectorAll('meta')].find(item=>item.getAttribute('property')=='og:url').setAttribute('content',href);

                await FB.init({
                    appId      : '821650112386957',
                    status     : true,
                    xfbml      : false,
                    version    : 'v2.8' // or v2.6, v2.5, v2.4, v2.3
                });

                await this.render({root,data:{href}});
                await FB.XFBML.parse();
            },
            async closeFbShare(){
                await this.fire.destroy();
            }
        }
    }
});