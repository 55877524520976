Cake.create('spinner', '#spinner', {
    root:'BODY',
    handlers:{
        destroy(e){
            this.reset();
        },
        click(e){
            let isdismiss = e.target.dissmiss == "true";
            if(isdismiss){
                this.fire.destroy();
            };
        },
        isConnected(){
            console.log('spinner is connected');
        }
    },
    subscribe:{
        renderSpinner:{
            components:['contact','api','model-remote','lobby'],
            handler(config){
                if(!config){
                    config = {};
                    config.dismiss = true;
                };
                this.render({data:config});
            }
        },
        destroySpinner:{
            components:['contact','api','model-remote','lobby'],
            handler(e){
                this.fire.destroy();
            },
        }
    },
});