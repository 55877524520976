Cake.create('alert', null, {
    handlers:{},
    subscribe:{
        alertNoCredit:{
            components:['lobby'],
            handler(e){
                return swal.fire({
                    title:'Insufficient Credit',
                    html:`You have insufficient credit to play. Minimum credit of <strong>PHP1000.00</strong> is needed to play, please fund your account and play again.`,
                    icon:'error',
                });
            },
        },
        alertUnderMaintenance:{
            components:['lobby'],
            handler(e){
                // console.log(e);
                // console.log(bootstrap);
            }
        },
        alertRedirection:{
            components:['header'],
            handler(e){
                return swal.fire({
                    title:'Redirection',
                    text:`You are about to redirect to the ${e}, click or tap "ok" to continue.`,
                    icon:'info',
                });
            }
        },
        alertContactSubmitSuccess:{
            components:['contact'],
            handler(e){
                return swal.fire({
                    title:'Success',
                    text:`Successfully submitted your inquiry`,
                    icon:'success',
                });
            }
        },
        alertContactSubmitError:{
            components:['contact'],
            handler(e){
                return swal.fire({
                    title:'Error',
                    text:`${e}`,
                    icon:'error',
                });
            }
        },
        alertRedirectToApp:{
            components:['lobby'],
            handler(e){
                return swal.fire({
                    title:'Redirection',
                    html:`
                        <div>
                            <p>Download our app to play at <a href='market://details?id=com.Breddas.Lucky888Bet'>Lucky888bet</a> to continue</p>
                            <br>
                            <p>Or if you already have our app installed</p>
                            <br>
                            <p>click or tap "ok" to continue.</p>
                        </div>
                    `,
                    icon:'info',
                });
            }
        },
        error:{
            components:['model-remote','register'],
            handler(e){
                if(!e){
                    e = 'something went wrong';
                };
                return swal.fire({
                    title:'Error',
                    text:`${e}`,
                    icon:'error',
                });
            }
        }
    },
})