Cake.create('footer', '#footer', {
    handlers:{
        click(e){
            let role = e.target.dataset.role;
            if (role == 'contact'){
                this.fire('openModal', role);
            };
        },
    },
    subscribe:{},
});