Cake.create('model-remote', null, {
    type:'model',
    utils(self){
        const _fetch = (method)=>{
            
            return (path, obj)=>{
                let config = {
                    method,
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit, 
                };
                if (obj && obj.body){
                    config.body = JSON.stringify(obj.body);
                    config.headers = {'Content-Type': 'application/json',};
                };
                return fetch(path,config);
            };
        };
        this.post = _fetch('POST');
        this.get = _fetch('GET');
    },
    handlers:{},
    subscribe:{
        lobby:{
            games(){
                return this.utils.get(`/api/games/${new Date().getTime()}`).then(res=>{
                    return res.json();
                });
            }
        },
        login:{
            submit(json){
                this.fire('renderSpinner');
                this.utils.post('/api/auth/login2',{
                    body:json
                }).then(res=>{
                    return res.json();
                }).then(async (res)=>{
                    console.log(res);

                    if(res.status){
                        // setTimeout(()=>{
                        //     this.fire('destroySpinner');
                        
                        //     window.location = '/redirect/auth';
                        // },1000)
                        await this.fire('destroySpinner');
                        await this.fire('success','Successfully login');

                        await location.reload();
                    } else {
                        this.fire('destroySpinner');
                        this.fire('error',res.message);
                    };



                }).catch(err=>{
                    console.log(err);
                    this.fire('destroySpinner');
                    this.fire('error',err.message);
                })
            }
        },
        register:{
            
            submitKyc(json){
                this.fire('renderSpinner');
                return this.utils.post('/api/user/preregister',{
                    body:json
                }).then(res=>{
                    return res.json();
                }).then(res=>{
                    console.log(res);
                    if(res.status){
                        setTimeout(()=>{
                            this.fire('destroySpinner');
                        
                            // window.location = '/redirect/auth';
                        },1000)
                    } else {
                        this.fire('destroySpinner');

                        if(res.message && res.message.details){

                            this.fire('error',res.message.details.reduce((accu, iter)=>{
                                accu += `${iter.message} ,`
                                return accu;
                            },''));
                        } else {
                            this.fire('error',res.message);
                        }

                        
                    }

                    return res;

                    
                }).catch(err=>{
                    console.log(err);
                    this.fire('destroySpinner');
                    this.fire('error',err.message);
                })
            }
        },
        otp:{
            async submit(json){
                await this.fire('renderSpinner',{dismiss:false});
                this.fire('disableButton');
 
            

                return this.utils.post('/api/user/register',{
                    body:json
                }).then(res=>{
                    return res.json();
                }).then(res=>{
                    // console.log(res);
                    
                    // return;
                    if(res.status){
                        setTimeout(()=>{
                            // this.fire('destroySpinner');
                        
                            window.location = '/redirect/auth';
                        },3000)
                    } else {
                        // this.fire('enableButton');
                        this.fire('destroySpinner');
                        this.fire('error',res.message);
                        return res;
                    };
                }).catch(err=>{
                    // console.log(err);
                    // this.fire('enableButton');
                    this.fire('destroySpinner');
                    this.fire('error',err.message);
                })
            }
        },
        header:{
            verify(){
                console.log('verify');
                this.fire('renderSpinner');
                return this.utils.get('/api/auth/verify').then(res=>{
                    return res.json();
                }).then(res=>{
                    this.fire('destroySpinner');
                    return res;
                }).catch(err=>{
                    console.log(err);
                })
            }
        }
    },
});