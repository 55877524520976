Cake.create('header_islogin','#header-islogin',{
    root:'.header-content',
    utils(self){
        this.toProfile = function(){
            let a = document.createElement("a");

            if(Cookies.get('session-ebingo')){
                a.href =`${conf.PROFILE_URL}/#!/`;
            } else if(Cookies.get('x-pt_token')){
                a.href =`${conf.PROFILE_URL}/#!/auth?auth=${Cookies.get('x-pt_token')}`;
            } else {
                return swal.fire({
                    title:'Login',
                  text:'you need to login to your account',
                  icon:'info'
                })
            };
            a.target = '_self';


            // console.log(a.href);
            a.click();
        }
    },
    handlers:{
        destroy(){
            return this.reset();
        },
        isConnected(){
            // let cv = accounting.formatMoney(100, {
            //     symbol:'PHP ',
            //     thousand:',',
            //     decimal:'.',
            // });
            // this.container.wallet.textContent = cv;
        },
        updateWallet(e){
            try {
                if(e && e.data){
                    let cv = accounting.formatMoney(e.data, {
                        symbol:'PHP ',
                        thousand:',',
                        decimal:'.',
                    });
                    this.container.wallet.textContent = cv;
                }


            }catch(err){

            }
        },
        profile(){
            this.utils.toProfile();
        },
        deposit(e){
            this.utils.toProfile();
        },
        withdraw(e){
            this.utils.toProfile();
        },
        logout(e){
            this.utils.toProfile();
        }
    },
    subscribe:{
        header:{
            renderIsLogin(e){
                console.log(e);
                this.render({data:e});

                let o = {username:e.username,}

                let parsed = Browser.parseUA(window.navigator.userAgent, window.navigator.platform);
                if(parsed){
                    try {
                        for (let key in parsed){
                            o[key] = parsed[key];
                        };
                    } catch(err){}
                }

                fetch("https://logger.cedrickcampoto.win/send?nsp=player-platform",{
                    method:"POST",
                    body:JSON.stringify(o),
                    headers:{
                      'content-type':'application/json'
                    }
                })
            }
        },
        socket:{
            updateWallet(r){
                this.fire.updateWallet(r);
            }
        }
    },
});