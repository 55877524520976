Cake.create('carousel', '#slide', {
    root:'.carousel-container',
    handlers:{
        beforeConnected(){
            this.$scope.games = [
                {display:"Fortune 30", img:"./image/games/bingo-fortune_30.png"},
                {display:"Peryahan", img:"./image/games/bingo-peryahan.png"},
                {display:"Golden Era", img:"./image/games/bingo-golden_era.png"},
                {display:"Gold Farm", img:"./image/games/bingo-gold_farm.png"},
                {display:"Sea Riches", img:"./image/games/bingo-sea_riches.png"},
                {display:"Pirate Babes", img:"./image/games/bingo-pirate_babes.png"},
            ];
        },
        isConnected(){
            console.log('carousel is connected');

            let template = this.container.games.querySelector('.cake-template');
            if (template){
                template.remove();
            }

            // const splide = this.data.splide = new Splide('.games', {
            //     type:'loop',
            //     rewind:true,
            //     autoplay:true,
            //     interval:3000,
            //     focus:'center',
            //     // height:'320px',
            //     width:'534px',
            //     breakpoints: {
            //         325: {
            //             width:'300px',
            //         },
            //         470: {
            //             width:'320px',
            //         },
            //         640: {
            //             width:'400px',
            //         },
            //         920:{
            //             width:'450px',
            //         }
            //     }
            // }).mount();

        
            // splide.on('active', this.fire.slideActive);
            // splide.on('hidden', this.fire.slideHide);
        },
        slideActive(e){
            // let slide = e.slide;

            // let cells = slide.querySelectorAll('.cell');

        },
        slideHide(e){
            let slide = e.slide;
        }
    },
    subscribe:{
        lobby:{
            isConnected(){
                this.renderAsync();
            }
        }
    },
});