Cake.create('modal_form','#modal-form',{
    root:'BODY',
    handlers:{
        async destroy(){
            return await this.reset();
        },
        isConnected(){
            let el = document.getElementById('exampleModal');
            this.data.modal = new bootstrap.Modal(el,{});
            this.data.modal.show();

            let _this = this;
            el.addEventListener('hide.bs.modal',(e)=>{
                let a = this.data.issubmit || false;
                _this.fire.destroy();
            });

            console.log('modal form is connected');
        },
  
    },
    subscribe:{
        openModal:{
            components:['login','register','otp'],
            handler(e){
                this.render({data:e});
            }
        },
        disableButton:{
            components:['otp','register','api','model-remote','otp'],
            handler(e){
                this.$scope.set('disabled', true);
            }
        },
        enableButton:{
            components:['otp','register','api','model-remote','otp'],
            handler(e){
                this.$scope.set('disabled', false);
            }
        }
    },
});