require('dotenv').config('../../');




module.exports = {

    BR_URL:'http://localhost:8000',
    BASE_URL:"http://localhost:5000",
    SOCKET_URL:"http://localhost:5001",
    GAME_DISABLE:['bingo-sea_riches','bingo-pirate_babes']
};