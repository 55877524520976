Cake.create('app','#app',{
    handlers:{
        isConnected(){
            let state = this.$router.state;
            if(state.auth == 'login'){
                this.fire('openLogin');
            } else if (state.auth == 'register'){
                this.fire('openRegister');
            }
        },
        checkSpeed(){
            return new Promise((res,rej)=>{
                var userImageLink = 
    "https://888bingo.ph/assets/images/lobby/background.png";
                var time_start, end_time;
                
                // The size in bytes
                var downloadSize = 5616998;
                var downloadImgSrc = new Image();
    
                downloadImgSrc.onload = function () {
                    end_time = new Date().getTime();
                    res(displaySpeed());
                };
                downloadImgSrc.onerror = function (e) {
                    rej(e);
                };

                time_start = new Date().getTime();
                downloadImgSrc.src = `${userImageLink}?v=${new Date().getTime()}`;
    
                function displaySpeed() {
                    var timeDuration = (end_time - time_start) / 1000;
                    var loadedBits = downloadSize * 8;
                    
                    /* Converts a number into string
                    using toFixed(2) rounding to 2 */
                    var bps = (loadedBits / timeDuration).toFixed(2);
                    var speedInKbps = (bps / 1024).toFixed(2);
                    var speedInMbps = (speedInKbps / 1024).toFixed(2);

                    return speedInMbps;
                }
            })
        }
    },
    subscribe:{
        lobby:{
            checkSpeed(){
                return this.fire.checkSpeed();
            }
        }
    },
});