Cake.create('header_notlogin','#header-notlogin',{
    root:'.header-content',
    handlers:{
        destroy(){
            return this.reset();
        },
        login(e){
            this.fire('openLogin');
        },
        register(e){
            this.fire('openRegister');
            // this.fire('openOtp');
        },
    },
    subscribe:{
        header:{
            renderNotLogin(){
                this.render();
            }
        }
    },
});