// let env = process.env.NODE_ENV;
let platform = process.env.PLATFORM_ENV;

let conf = {};
if(platform == 'local') {
    if(process.env.NODE_ENV == 'development'){
        let _conf =  require('../../../config/local/config.development-fe');

        conf.SOCKET_URL = _conf.SOCKET_URL;
        conf.PROFILE_URL = 'http://localhost:8300';
        
    } else if(process.env.NODE_ENV == 'production'){
        let _conf =  require('../../../config/local/config.production-fe');
        
        conf.PROFILE_URL = 'http://localhost:8300';
        conf.SOCKET_URL = _conf.SOCKET_URL;
    };
} else if (platform == 'remote'){
    if(process.env.NODE_ENV == 'development'){
        let _conf =  require('../../../config/remote/config.development-fe');
        
        conf.PROFILE_URL = 'https://ebingo-fe-profile.pages.dev';
        conf.SOCKET_URL = _conf.SOCKET_URL;
    } else if(process.env.NODE_ENV == 'production'){
        let _conf =  require('../../../config/remote/config.production-fe');
        
        conf.PROFILE_URL = 'https://ebingo-fe-profile.pages.dev';
        conf.SOCKET_URL = _conf.SOCKET_URL;
    };
};

window.conf = conf;


import './components/app';
import './components/alert';
import './components/contact';
import './components/details';
import './components/footer';
import './components/header';
import './components/lobby';
import './components/modal';
import './components/api';
import './components/slide';
import './components/fb-share';
import './components/socket';
import './components/jackpot';
import './components/spinner';
import './components/ads';
import './components/login';
import './components/register';
import './components/otp';
import './components/header-islogin';
import './components/header-notlogin';
import './components/modal-form';