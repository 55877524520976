Cake.create('lobby', '#lobby',{
    data(self){
        this.wallet = 0;
        this.current = 1;
        this.cache = null;
    
    },
    handlers:{
        clickOther(e){
            
        },
        initShare(e){
            let target = e.target;
            let parent = target.closest('.game-content');
            let a = parent.querySelector('[data-game]');
            if(a){
                let game = a.dataset.game;
                let conf = this.data.games.find(item=>item.name == game);
                
                try {
                    let container = document.querySelector(`.social-content-${game}`);
                    let url = `${window.location.origin}${conf.href.demo}`;

                    
                    let cust = document.querySelectorAll('.cust-fb-share');
                    [...cust].forEach(item=> {
                        item && item.classList && item.classList.remove('d-none');
                    });
                    
                    
                    this.fire('renderFbShare',{root:container,href:url}).then(()=>{
                        let cust = container.querySelector('.cust-fb-share');
                        cust.classList.add('d-none');
                    });
                } catch(err){};
            };
        },
        click(e){
            let target = e.target;
            let a = target.closest('A');

            if(a){
                let game = a.dataset.game;
                let conf = this.data.games.find(item=>item.name == game);     

                

                let {enabled, status:{demo, lobby}} = conf;
                if(enabled){
                    let gcont = a.closest('.game-content');
                    if(gcont){
                        gcont.classList.toggle('options-open');
                    };
                } else {
                    this.fire('alertUnderMaintenance',{
                        title:'Maintenance',
                        message:`${conf.title} is under maintenance`,
                    }).then(res=>{
                        console.log(res);
                        console.log('modal has dismissed');
                    });
                };


            } else {

            }

        },
        async isConnected(){ 
            console.log('lobby connected')

            if(Cookies){
                let ck = Cookies.get('x-fd');


                try {
                    let v;
                    if(ck == undefined){
                        v = false;
                    } else {

                        if(ck == "false"){

                            // this.fire('showDepositNow')
                        };
                    };

                } catch(err){};
            };


            let res = await this.fire('games');
            if(res.status){
                let {classic, variant, gamesmart} = res.data;

                this.data.games = [];

                if(classic){
                    this.data.games = this.data.games.concat(classic);
                };
                if(variant){
                    this.data.games = this.data.games.concat(variant);
                };

                if(gamesmart){
                    this.data.games = this.data.games.concat(gamesmart);
                    this.container.gamesmart.classList.remove('d-none');
                };
    

                classic && this.fire.preload({name:'classic',arr:classic});
                variant && this.fire.preload({name:'variant',arr:variant});
                gamesmart && this.fire.preload({name:'gamesmart',arr:gamesmart});

                classic && await this.$scope.set('classics',classic.map(item=>{
                    let {name, enabled,href,icon,status,title,} = item;
                    return {
                        group:'classics',
                        name:name,
                        status:enabled,
                        icon_bg:icon.bg,
                        icon_logo:icon.logo,
                        href_demo:href.demo,
                        href_lobby:href.lobby,
                        title:title,
                        "is-auth":item['is-auth']
                    };
                }));
                
                variant && this.$scope.set('variants',variant.map(item=>{
                    let {name, enabled,href,icon,status,title,} = item;
                    return {
                        group:'variants',
                        name:name,
                        status:enabled,
                        icon_bg:icon.bg,
                        icon_logo:icon.logo,
                        href_demo:href.demo,
                        href_lobby:href.lobby,
                        title:title,
                        "is-auth":item['is-auth']
                    };
                }));

                gamesmart && this.$scope.set('gamesmart',gamesmart.map(item=>{
                    let {name, enabled,href,icon,status,title,} = item;
                    return {
                        group:'gamesmart',
                        name:name,
                        status:enabled,
                        jackpot_icon:icon.jackpot,
                        icon_logo:icon.logo,
                        href_demo:href.demo,
                        href_lobby:href.lobby,
                        title:title,
                        "is-auth":item['is-auth']
                    };
                }));




            };
        },
        preload({name:gr, arr}){
            return Promise.all(arr.map(item=>{
                let {name,icon:{bg,jackpot,logo}} = item;
                if(gr == 'gamesmart'){
                    bg = logo;
                    logo = jackpot;
                };

                return new Promise((res)=>{
                    

                    let bgImage = new Image();
                    let logoImage = new Image();

                    bgImage.src = bg;
                    logoImage.src = logo;

                    let s = false;
                    bgImage.onload = function(){
                        let el = document.querySelector(`[data-game=${name}] .icon-bg`);
                        el.src=bg;
                        if(!s){
                            s = true;
                        } else {
                            setTimeout(()=>{res()},1000);
                        };
                    };
                    bgImage.onerror = function(){setTimeout(()=>{res()},1000);};

                    logoImage.onload = function(){
                        setTimeout(()=>{
                            // console.log(157,`[data-game=${name}] .icon-logo`);
                        let el = document.querySelector(`[data-game=${name}] .icon-logo`);

                        el && (el.src=logo);
                        if(!s){
                            s = true;
                        } else {
                            setTimeout(()=>{res()},1000);
                        };
                        },500)
                    };
                    logoImage.onerror = function(){setTimeout(()=>{res()},1000);};
                }).then(()=>{
                    let skeleton = document.querySelector(`.${gr}-skeleton`);
                    if(skeleton){
                        skeleton.classList.add('hide');
                    }
                }).then(res=>{
                    let game = document.querySelector(`[data-game=${name}]`);
                    if(game){
                        let content = game.closest('.game-content');
                        content.classList.replace('hide','show');
                    }
                });


            }))
        },
        async freePlay(e){
            let target = e.target;
            let game = target.dataset.game;
            let conf = this.data.games.find(item=>item.name == game);

            if(!conf){return;};
            let {href:{demo}} = conf;
            await new Promise((res)=>{
                if(this.utils.isDesktop()){
                    this.fire('checkSpeed')
                        .then(mbps=>{
                            
                            // alert(`mbps ${mbps}`)

                            if(mbps < 140){
                                console.log('render low res');
                                demo = `${demo}?ispd=lwoenc`;
                            } else {
                                console.log('render high res');
                            };
                        }).then(()=>{
                            res(demo);
                        }).catch(err=>{
                            console.log(err);
                            res(demo);
                        });
                } else {
                    res(demo);
                };

                
            }).then(url=>{
                window.location = url;
            });


        },
        async livePlay(e){
            let target = e.target;
            let game = target.dataset.game;
            let conf = this.data.games.find(item=>item.name == game);

            if(!conf){return;};
            let {href:{lobby}} = conf;
            await new Promise((res)=>{
                if(this.utils.isDesktop()){
                    this.fire('checkSpeed')
                        .then(mbps=>{
                            
                            // alert(`mbps ${mbps}`);

                            if(mbps < 140){
                                console.log('render low res');
                                lobby = `${lobby}?ispd=lwoenc`;
                            } else {
                                console.log('render high res');
                            };
                        }).then(()=>{
                            res(lobby);
                        }).catch(err=>{
                            console.log(err);
                            res(lobby);
                        });
                } else {
                    res(lobby);
                };

                
            }).then(url=>{
                window.location = url;
            });
        },
        gameOptionClose(e){
            let target = e.target;
            let gcont = target.closest('.game-content');
            if(gcont){
                gcont.classList.toggle('options-open');

                this.fire('closeFbShare');
            };
        },

    },
    subscribe:{
        socket:{
            // updateJackpot(e){
            //     return this.fire.updateJackpot(e);
            // },
            // updateWallet(obj){
            //     this.data.wallet = obj.wallet;
            // }
        }
    },
})