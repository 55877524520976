Cake.create('socket', null, {
    data(){
        this.isloaded = false;
    },
    init(){
        window.socket = io(`${conf.SOCKET_URL}/lobby`,{
            withCredentials: true,
            'sync disconnect on unload': true, 
            transports: ["websocket"],

        });
        socket.on('connect', (e)=>{
            socket.sendBuffer = [];
            console.log(`game lobby is connected to server socket with socket ${socket.id}`);
            // socket.emit('connected');

        });
        socket.on('/hasGamePlay', ()=>{
            // location.replace(`${location.origin}/unauthorised`);
        });
        socket.on("disconnect", ()=>{
            console.log('disconnected');
            // socket.connect();
        });

        let retry = 5;
        let count =  0;

        let updatePlayer = ()=>{
            // console.log('update-player');
            // console.log(count);
            setTimeout(()=>{
                socket.emit('/update-player', {}, (response)=>{
                    // console.log('update-player',response);

                    this.fire('updateWallet',response);
                    updatePlayer();

                    if (response.status){

                    } else {
                        
                        // location.replace(`${location.origin}/public-lobby`);

                        // if (count < retry){
                        //     updatePlayer();
                        //     count += 1;

                        // } else {
                        //     alert('not logged in');
                        //     location.replace('https://888bingo.ph/public-lobby');
                        // };
                    };
                })
            }, 1000);
        };
        updatePlayer();

        let updateJackpot = ()=>{
            setTimeout(()=>{
                socket.emit('/update-jackpot', {}, (response)=>{
                    this.fire.updateJackpot(response).then(()=>{
                        updateJackpot();
                    });
                })
            }, 900);
        };
        updateJackpot();
    },
    utils(){
        this.request = function(route, payload){
            // console.log(route, payload)
            return new Promise((res, rej)=>{
                socket.emit(route, payload, (response)=>{
                    res(response);
                })
            })
        }
    },
    handlers:{

        updateJackpot(e){
            return this.fire('updateJackpot', e);
        },
        getPlayer(){
            return this.utils.request('/getPlayer', {}).then((r)=>{
                // console.log('getPlayer',r);
                if (r.status != undefined && r.status == 0){

                    // location.replace(`${location.origin}/loader?token=1`);
                    
                } else {
                    // console.log(r);
                    this.fire('updateUser', r);
                    return r;
                };
            });
        },
        playerState(game){
            return this.utils.request('/getPlayerState', {game}).then((r)=>{
                console.log('getPlayerState',r);
                if (r.status != undefined && r.status == 0){
                    // this.fire('alertNoCredit', game)


                    // location.replace(`${location.origin}/`);


                    // .then(()=>{
                    //     location.replace(`${location.origin}/`);

                    // });
                } else {
                    // console.log(r);
                    // this.fire('updateUser', r);
                    return r;
                };
            });
        },
        contactUs(e){
            return this.utils.request('/contactUs', e).then((r)=>{
                return r;
            });
        },
        getDetails(name){
            return this.utils.request('/getDetail', name).then((r)=>{
                return r;
            });
        },
        logPartner(){
            console.log('isLoggedPartner');
            return this.utils.request('/logPartner',{});
        },
    },
    subscribe:{
        lobby:{
            getPlayer(){
                this.fire.getPlayer();
            },
            getPlayerState(game){
                return this.fire.playerState(game);
            },
            logPartner(){
                return this.fire.logPartner();
            }
        },
        contact:{
            contactUs(e){
                return this.fire.contactUs(e);
            }
        },
        details:{
            getDetails(name){
                return this.fire.getDetails(name);
            }
        }
    },
})